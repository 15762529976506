export default [
  {
    path: '/modelos',
    name: 'modelos',
    component: () => import('@/views/desafios/listarModelosDesafios.vue'),
  },
  {
    path: '/modelos/:modeloId/editar',
    name: 'editarModelo',
    props: true,
    component: () => import('@/views/desafios/formModeloDesafio.vue'),
  },
  {
    path: '/desafios/:modeloId/novo',
    name: 'novodesafio',
    props: true,
    component: () => import('@/views/desafios/novoDesafio.vue'),
  },
  {
    path: '/desafios/:id/editar',
    name: 'editarDesafio',
    props: true,
    component: () => import('@/views/desafios/novoDesafio.vue'),
  },
  {
    path: '/todosdesafios',
    name: 'todosdesafios',
    component: () => import('@/views/desafios/todosDesafios.vue'),
  },
  {
    path: '/quiz_novo',
    name: 'quiz_novo',
    component: () => import('@/views/desafios/components/Quiz.vue'),
  },
  {
    path: '/novoDesafio',
    name: 'novoDesafio',
    component: () => import('@/views/desafios/novoDesafioSelecionarModelo.vue'),
  },
  {
    path: '/avaliar/',
    name: 'avaliar',
    props: true,
    component: () => import('@/views/desafios/avaliar/avaliar-list.vue'),
  },
  {
    path: '/avaliar/arboturma/:id',
    name: 'avaliarArboturma',
    props: true,
    component: () => import('@/views/desafios/avaliar/avaliar-arboturma.vue'),
  },
  {
    path: '/avaliar/janelatanque/:id',
    name: 'avaliarJanelaTanque',
    props: true,
    component: () => import('@/views/desafios/avaliar/avaliar-janela-tanque.vue'),
  },
  {
    path: '/avaliar/patrulhafoco/:id',
    name: 'patrulhaFoco',
    props: true,
    component: () => import('@/views/desafios/avaliar/avaliar-patrulha.vue'),
  },
]
