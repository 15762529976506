export default [
  {
    path: '/novocomponente',
    name: 'novocomponente',
    component: () => import('@/views/componentes/NovoComponente.vue'),
  },
  {
    path: '/listacomponentes',
    name: 'listacomponentes',
    component: () => import('@/views/componentes/ComponentesLista.vue'),
  },

  {
    path: '/listacomponentes',
    name: 'listacomponentes',
    component: () => import('@/views/componentes/ComponentesLista.vue'),
  },

  {
    path: '/EscolasLista',
    name: 'EscolasLista',
    component: () => import('@/views/componentes/instituicoes/EscolasLista.vue'),
  },

  {
    path: '/NovaEscola',
    name: 'NovaEscola',
    component: () => import('@/views/componentes/instituicoes/NovaEscola.vue'),
  },

  {
    path: '/ProfessorLista',
    name: 'ProfessorLista',
    component: () => import('@/views/componentes/instituicoes/ProfessorLista.vue'),
  },
  {
    path: '/ProfessorLista/editar/:id',
    name: 'ProfessorEditar',
    component: () => import('@/views/componentes/instituicoes/ProfessorForm.vue'),
  },
  {
    path: '/ProfessorLista/cadastrar',
    name: 'ProfessorCadastrar',
    component: () => import('@/views/componentes/instituicoes/ProfessorForm.vue'),
  },

  {
    path: '/Alunos',
    name: 'Alunos',
    component: () => import('@/views/componentes/instituicoes/Alunos.vue'),
  },



 
  
]


