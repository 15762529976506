import Vue from 'vue'

// axios
import axios from 'axios'
import * as moment from 'moment'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://arboedu-api.moiotech.com/api',
  // baseURL: 'http://localhost:2001/api',

  // timeout: 1000,
  // headers: {
  //   "Accept": '*/*',
  // },
})

axiosIns.interceptors.response.use(
  request => {
    return request
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')
      window.location.href = '/'

      // TODO - Implementar redirecionamento
      const token = localStorage.getItem('accessToken')
      if (token) {
        const timeOfExpire = JSON.parse(atob(token.split('.')[1])).exp
        console.log(moment(timeOfExpire))
      }
    }
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
