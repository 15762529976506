export default [
  {
    path: '/profissionais',
    name: 'profissionais',
    component: () => import('@/views/admin/profissionais_lista.vue'),
  },
  {
    path: '/instituicoes',
    name: 'instituicoes',
    component: () => import('@/views/admin/instituicoes_lista.vue'),
  },
  {
    path: '/instituicoes/:id',
    name: 'instituicoesEdit',
    component: () => import('@/views/admin/add_instituicao.vue'),
  },
  {
    path: '/escolas',
    name: 'EscolasLista',
    component: () => import('@/views/componentes/instituicoes/EscolasLista.vue'),
  },
  {
    path: '/escolas/:id',
    name: 'escolasEdit',
    component: () => import('@/views/componentes/instituicoes/NovaEscola.vue'),
  },
  {
    path: '/escolas/:id/turmas/',
    name: 'listaTurmas',
    component: () => import('@/views/componentes/instituicoes/turmas/TurmasLista.vue'),
  },
  {
    path: '/escolas/:id/turmas/adicionar',
    name: 'adicionarTurma',
    component: () => import('@/views/componentes/instituicoes/turmas/NovaTurma.vue'),
  },
  {
    path: '/escolas/:id/turmas/:turmaId/editar',
    name: 'editarTurma',
    component: () => import('@/views/componentes/instituicoes/turmas/NovaTurma.vue'),
  },

  {
    path: '/add_instituicao',
    name: 'add_instituicao',
    component: () => import('@/views/admin/add_instituicao.vue'),
  },

  {
    path: '/novoProfissional',
    name: 'profissionalAdd',
    component: () => import('@/views/admin/add_profissional.vue'),
  },

  {
    path: '/profissional/:id',
    name: 'profissionalEdit',
    component: () => import('@/views/admin/add_profissional.vue'),
    props: true,
  },
]
